@import "bootstrap-override";

/*PATHES*/
$icon-font-path: "/components/bootstrap-sass/assets/fonts/bootstrap/";
$fa-font-path: "/components/font-awesome/fonts";

/*RESOLUTIONS*/
$lg-min-screen: 1200px;
$md-min-screen: 992px;
$sm-min-screen: 768px;


/*COLORS*/
$white: #fff;
$main-bg: #e9ecf3;
$main-color: #535865;
$border-color: #e4e6ec;
$nav-bg: #2f323a;
$error-border-color: #f3a9b9;
$error-shadow-color: #ec6565;
$placeholder-color: #8d93a3;
$input-border-color: #c1c4d3;
$custom-input-bg: #f6f7fa;
$top-th-bg: #80879e;

/*buttons*/
$btn-blue-color: #296dbc;
$btn-blue-hover: #1e518c;
$btn-green-color: #50c14e;
$btn-green-hover: #0da90a;
$btn-violet-color: #9da5be;
$btn-violet-hover: #6c7388;

/*alerts*/
$danger-color: #c12e2a;
$info-color: #296dbc;
$success-color: #50c14e;
$warning-color: #FF9000;

/*BOOTSTRAP variables*/
//$screen-lg-min: 320px;
$link-hover-color: inherit;
$pagination-active-bg: $top-th-bg;
$pagination-active-border: $top-th-bg;