@import 'variables';

$inputHeight: 40px;
$labelWidth: 100px;

%bg {
  background: #E9ECF3
}

body {
  font: 14px/20px 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #404040;
  @extend %bg;
}

a {
  color: $btn-green-color;
  text-decoration: none;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
}

.login {
  position: relative;
  margin: 160px auto;
  width: 300px;
  padding-right: 32px;
  font-weight: 300;
  color: #a8a7a8;

  .logo {
    text-align: center;
    max-width: 90px;
    margin: 0 auto;
  }

  h1 {
    color: #535865;
    text-align: center;
    line-height: 1.1;
    span {
      color: #50c14e;
    }
  }

  p {
    margin: 0 0 10px;
  }

  input, button, label {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: 300;
    box-sizing: border-box;
  }

  input[type=text], input[type=password] {
    padding: 0 10px;
    width: 100%;
    height: $inputHeight;
    border: 0;
    border-radius: 5px;
    box-shadow: inset 0 0 0 2px #535865;
    &:focus {
      outline: 0;
    }
  }

  label {
    float: left;
    width: $labelWidth;
    line-height: $inputHeight;
    padding-right: 10px;
    font-weight: 100;
    text-align: right;
    letter-spacing: 1px;
  }

  .forgot-password {
    font-size: 13px;
    font-weight: 100;
    letter-spacing: 1px;
  }

  .login-submit {
    position: absolute;
    top: 17px;
    right: -32px;
    width: 54px;
    height: 54px;
    padding: 3px;
    border-radius: 32px;
    box-shadow: 0 0 0 2px #535865;
    @extend %bg;

    &:before, &:after {
      content: '';
      z-index: 1;
      position: absolute;
      @extend %bg;
    }

    &:before {
      top: 23px;
      left: -3px;
      width: 4px;
      height: 10px;
      box-shadow: inset 0 1px rgba(255, 255, 255, .06);
    }

    &:after {
      top: -4px;
      bottom: -4px;
      right: -4px;
      width: 36px;
    }
  }

  #log-error {
    color: $danger-color;
  }

  .login-button {
    position: relative;
    z-index: 2;
    width: 48px;
    height: 48px;
    padding: 0 0 48px; /* Fix wrong positioning in Firefox 9 & older (bug 450418) */
    text-indent: 120%;
    white-space: nowrap;
    overflow: hidden;
    background: none;
    border: 0;
    border-radius: 24px;
    cursor: pointer;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .2),
    0 1px rgba(255, 255, 255, .1);

    &:hover:before {
      background-color: $btn-green-color;
      background-image: none;
    }

    &:before {
      content: '';
      position: absolute;
      top: 5px;
      bottom: 5px;
      left: 5px;
      right: 5px;
      border-radius: 24px;
      background-color: $btn-green-color;
      background-image: linear-gradient(to top, $btn-green-color, $btn-green-hover);
      box-shadow: inset 0 0 0 1px #50c14e, 0 0 0 5px rgba(71, 195, 59, 0.54);
    }

    &:active:before {
      background-color: $btn-green-color;
      background-image: linear-gradient(to top, $btn-green-color, $btn-green-hover);
    }

    &:after {
      content: '';
      position: absolute;
      top: 15px;
      left: 12px;
      width: 25px;
      height: 19px;
      background: url('../img/login-form/arrow.png') 0 0 no-repeat;

    }
  }

  ::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  .lt-ie9 {
    input[type=text], input[type=password] {
      line-height: $inputHeight;
      background: #282828;
    }

    .login-submit {
      position: absolute;
      top: 12px;
      right: -28px;
      padding: 4px;

      &:before, &:after {
        display: none;
      }
    }

    .login-button {
      line-height: 48px;
    }

    .about {
      background: #313131;
    }
  }
}
